import { useTranslation } from 'next-i18next'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

const FilterLeadFormSubmitButton = () => {
  const { t } = useTranslation('common')

  return (
    <div className={`an-forward mt-2`}>
      <ThemeImage src={'/images/elements/filter/search-label.png'} alt={t('travelFilter.button.search')} width={115} height={89} />
    </div>
  )
}

export default FilterLeadFormSubmitButton
